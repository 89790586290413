var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật mẫu nhận xét",
        visible: _vm.dialogVisible,
        width: "50%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "udpateEvaluateSample",
          attrs: {
            "label-width": "120px",
            model: _vm.udpateEvaluateSample,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Loại nhận xét", prop: "evaluateType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Chọn loại nhận xét", disabled: "" },
                  model: {
                    value: _vm.udpateEvaluateSample.evaluateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.udpateEvaluateSample, "evaluateType", $$v)
                    },
                    expression: "udpateEvaluateSample.evaluateType",
                  },
                },
                _vm._l(_vm.evaluateTypeList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: item.key, label: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Nội dung", prop: "evaluateContent" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 5,
                  type: "textarea",
                  placeholder: "Nhập nội dung",
                },
                model: {
                  value: _vm.udpateEvaluateSample.evaluateContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.udpateEvaluateSample, "evaluateContent", $$v)
                  },
                  expression: "udpateEvaluateSample.evaluateContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("udpateEvaluateSample")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("udpateEvaluateSample")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }